import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styles from './PrivacyPolicyCovenant.module.css'
import { FlexContainer } from '@layout/FlexContainer'
import { PageContent } from '@layout/Page'

export const PrivacyPolicyCovenant: React.FC = () => {
  const { t } = useTranslation()

  return (
    <PageContent>
      <h3 className={styles.title}>{t('pages.privacyPolicy.covenant.title')}</h3>
      <FlexContainer vertical className={styles.privacyPolicyCovenantSectionContainer}>
        <h4 className={styles.subtitle}>{t('pages.privacyPolicy.covenant.chapter_one.title')}</h4>
        <Trans
          i18nKey="pages.privacyPolicy.covenant.chapter_one.text"
          components={{
            p: <p></p>
          }}
        />
      </FlexContainer>
      <FlexContainer vertical className={styles.privacyPolicyCovenantSectionContainer}>
        <h4 className={styles.subtitle}>{t('pages.privacyPolicy.covenant.chapter_two.title')}</h4>
        <Trans
          i18nKey="pages.privacyPolicy.covenant.chapter_two.text"
          components={{
            p: <p></p>
          }}
        />
      </FlexContainer>
      <FlexContainer vertical className={styles.privacyPolicyCovenantSectionContainer}>
        <h4 className={styles.subtitle}>{t('pages.privacyPolicy.covenant.chapter_three.title')}</h4>
        <Trans
          i18nKey="pages.privacyPolicy.covenant.chapter_three.text"
          components={{
            p: <p></p>,
            ul: <ul></ul>,
            li: <li></li>
          }}
        />
      </FlexContainer>
    </PageContent>
  )
}
